import React from 'react'
const MatxLogo = ({logoUrl}) => {

    return  (
        <img
            alt="Remy Sharp"
            src={`${logoUrl?logoUrl:"https://cred-man01.s3.ap-southeast-2.amazonaws.com/logo/RVL_Logo_White.png"}`}
            style={{
                margin:'5px',
                width:"100px",
                height:"100px"
            }}
        />
    )
}

export default MatxLogo
