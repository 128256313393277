import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { API_URI } from 'config'
import {  useNavigate, } from 'react-router-dom'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const navigate = useNavigate()
   

    const login = async (email, mfaCode) => {
    
        const response = await axios.post(`${API_URI}/api/admin/auth/vefify-mfa`, {
            email,
            mfaCode,
        })
        
        const { token, user } = response.data;
        window.localStorage.setItem("ROLE", user.role)

        setSession(token)

        dispatch({
            type: 'LOGIN',
            payload: {
                user
            },
        })
    }

    const register = async (email, password) => {
        const response = await axios.post(`${API_URI}/api/admin/auth/register`, {
            email,
            password,
        })

        const { accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        window.localStorage.removeItem("ROLE");
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        (async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');

                if (accessToken){
                    // setSession(accessToken)
              
                    const {data} = await axios.get(`${API_URI}/api/admin/me`, {
                        headers:{
                            authorization:`Bearer ${accessToken}`
                        }
                    })
                    
                    
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user:{
                                ...data
                            },
                        },
                    })
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                    window.localStorage.removeItem("accessToken")
                    window.localStorage.removeItem("ROLE")
                    navigate("/")
                    
                }
            } catch (err) {
                
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
                window.localStorage.removeItem("accessToken")
                navigate("/")
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
