import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const ForgotPassword = Loadable(lazy(() => import("./ForgotPassword")));
const JwtLogin = Loadable(lazy(() => import("./login/JwtLogin")))
const MFaVerificaion = Loadable(lazy(() => import("./login/VerifyMFA")))

const sessionRoutes = [
    {
        path: '/session/signin',
        element: <JwtLogin />,
    },
    {
        path: '/process/session/verify-mfa',
        element: <MFaVerificaion />,
    },
    {
        path: '/session/forgot-password',
        element: <ForgotPassword />,
    }
]

export default sessionRoutes
