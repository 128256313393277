import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';

const Analytics2 = Loadable(lazy(() => import("./Analytics2")));
const Analytics3 = Loadable(lazy(() => import("./Analytics3")));

export const dashboardRoutes = [
    {
        path: '/dashboard/analytics',//SUPERADMIN
        element: <Analytics3 />,
    },
    {
        path: '/dashboard/default', //ADMIN and SUPPORT_ADMIN
        element: <Analytics2 />,
    }
]
